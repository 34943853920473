<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Döviz Güncelleme",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      loading: true,
      title: "Döviz Güncelleme",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Döviz Yönetimi",
          href: "/exchange",
        },
        {
          text: "Döviz Güncelleme",
          active: true,
        },
      ],
      isAlertVisibleSuccess: false,
      isAlertVisibleDanger: false,
      values: {
        code: "",
        name: "",
        total: "",
        symbol: "",
      },
      isButton: false,
    };
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/exchange/${itemId}`;
      this.isButton = true;
      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.values = response.data?.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
          this.isButton = false;
        });
    },
    showAlertSuccess() {
      this.isAlertVisibleSuccess = true;
    },
    showAlertDanger() {
      this.isAlertVisibleDanger = true;
    },
    onSubmit() {
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/exchange/${itemId}`;
      this.isButton = true;
      const data = {
        code: this.values.code,
        name: this.values.name,
        total: this.values.total,
        symbol: this.values.symbol,
      }
      axios
        .put(api_url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.showAlertSuccess();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() =>{
          this.isButton = false;
        })
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div v-if="loading"></div>
    <div v-else>
      <div class="row justify-content-center">
        <div class="col-12 col-md-6">
          <div class>
            <b-alert v-if="isAlertVisibleSuccess" show variant="success">Başarıyla güncellendi.</b-alert>
            <b-alert v-if="isAlertVisibleDanger" show variant="danger">Hata! Lütfen daha sonra tekrar deneyiniz.</b-alert>
          </div>
          <div class="card mt-4">
            <div class="card-body">
              <form @submit.prevent="onSubmit">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group mb-3">
                      <label for="code">Döviz Kodu:</label>
                      <input v-model="values.code" type="text" class="form-control" id="code" />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group mb-3">
                      <label for="name">Döviz Adı:</label>
                      <input v-model="values.name" type="text" class="form-control" id="name" />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group mb-3">
                      <label for="total">Toplam:</label>
                      <input v-model="values.total" type="text" class="form-control" id="total" />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group mb-3">
                      <label for="symbol">Simbol:</label>
                      <input v-model="values.symbol" type="text" class="form-control" id="symbol" />
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end align-items-center">
                  <button type="submit" class="btn btn-primary" :disabled="isButton">
                    Güncelle
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
